<template>
<div>
  <v-app>
  <Calendar />
</v-app>
  </div>
</template>


<script>
  import Calendar from '../components/Calendar'

  export default {
    name: 'Schedule',

    components: {
      Calendar,
    },
  }
</script>
